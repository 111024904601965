<template>
    <div style="padding:2rem" id="mites">
            <v-form
            ref="form"
            lazy-validation
            >
            <br /><br />
            <v-row>
                <v-col cols="12" sm="12" class="text-center"> 
                    <p>
                        Nous ne sommes pas des spécialistes de la maille, nous pouvons proposer uniquement des coutures simples
                    </p>
                    <img src="https://www.lesreparables.fr/cv2/clothetype/img/remaillage.png" class="text-center"/>
                </v-col>
        <v-col cols="12" class="text-center">
            <div class="btn btn-1 btn-full text-center btn-size" @click="gotTabCouture">UNE COUTURE SIMPLE ME SUFFIT</div>
        </v-col>
        <v-col cols="12" class="text-center">
            <div class="btn btn-2 btn-full text-center btn-size" @click="reset">JE FAIS APPEL À UN REMAILLEUR</div>
        </v-col>
            </v-row> 
        </v-form>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations('flux-app', ['setConstat','setcptProduct', 'setcptReparation','setActiveTab','setLevel1','setLevel2', 'setLevel3', 'setComponentName']),
    gotTabCouture() {
       this.setActiveTab('reparation_simple');
    },
    reset() {
        this.setLevel1(null)
        this.setLevel2(null)
        this.setLevel3(null)
        this.setComponentName(null)
        this.$emit('evenement', 'scroll1')
    }
  },
}
</script>

<style scoped>
table {
    max-width: 390px;
}

table td.picto, table td.picto img {
    width: 100px;
}

table td.picto {
    padding: 20px 20px 20px 0;
}

.btn {
    background-color: #000;
    width: 120px;
    padding: 10px;
    color: #fff
}

.grey {
    background-color: #F8F8F8 !important;
}

#mites img {
    max-width: 390px;
}
</style>